import Main from "./Components/Main";
import Header from "./Components/Header";
import Footer from "./Components/Footer";

const Todo = () => {
  return (
    <>
      <Header />
      <Main />
      <Footer />
    </>
  );
};

export default Todo;
