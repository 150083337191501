import React, { useState, useEffect } from "react";
import { ITask } from "../Interfaces/Task";
import styles from "./TaskForm.module.css";

type Props = {
  btnText: string;
  taskList: ITask[];
  setTaskList?: React.Dispatch<React.SetStateAction<ITask[]>>;
  taskUpdate?: ITask | null;
  handleUpdate?(id: number, title: string, difficulty: number): void;
};

const TaskForm = ({ btnText, taskList, setTaskList, taskUpdate, handleUpdate }: Props) => {
  const [id, setId] = useState<number>(0);
  const [title, setTittle] = useState<string>("");
  const [difficulty, setDifficulty] = useState<number>(0);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (handleUpdate) {
      handleUpdate(id, title, difficulty);
    } else {
      setId((prevState) => prevState + 1);
      const newTask: ITask = { id, title, difficulty };
      setTaskList!([...taskList, newTask]);
      setTittle("");
      setDifficulty(0);
      console.log(title);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "title") {
      setTittle(e.target.value);
    } else {
      setDifficulty(parseInt(e.target.value));
    }
  };

  useEffect(() => {
    if (taskUpdate) {
      setId(taskUpdate.id);
      setTittle(taskUpdate.title);
      setDifficulty(taskUpdate.difficulty);
    }
  }, [taskUpdate]);

  return (
    <div>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.container}>
          <label htmlFor="title">Título:</label>
          <input
            type="text"
            name="title"
            value={title}
            placeholder="Título da tarefa"
            onChange={handleChange}
            required
          />
        </div>
        <div className={styles.container}>
          <label htmlFor="difficulty">Dificuldade:</label>
          <input
            type="number"
            name="difficulty"
            value={difficulty}
            placeholder="Dificuldade da tarefa"
            onChange={handleChange}
            required
          />
        </div>
        <input type="submit" value={btnText} />
      </form>
    </div>
  );
};

export default TaskForm;
