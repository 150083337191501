import { ITask } from "../Interfaces/Task";
import styles from "./TaskList.module.css";

type Props = {
  taskList: ITask[];
  deleteTask(id: number): void;
  editTask(task: ITask): void;
};

const TaskList = ({ taskList, deleteTask, editTask }: Props) => {
  return (
    <>
      {taskList.length ? (
        taskList.map((task) => (
          <div className={styles.task} key={task.id}>
            <div className={styles.details}>
              <h4>{task.title}</h4>
              <p>Dificuldade: {task.difficulty}</p>
            </div>
            <div className={styles.actions}>
              <i onClick={() => editTask(task)} className="bi bi-pencil"></i>
              <i onClick={() => deleteTask(task.id)} className="bi bi-trash"></i>
            </div>
          </div>
        ))
      ) : (
        <p>Não há tarefas cadastradas</p>
      )}
    </>
  );
};

export default TaskList;
