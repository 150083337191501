import { useState } from "react";
import { ITask } from "../Interfaces/Task";

import Modal from "./Modal";
import TaskForm from "./TaskForm";
import TaskList from "./TaskList";

const Main = () => {
  const [taskList, setTaskList] = useState<ITask[]>([]);
  const [taskToUpdate, setTaskToUpdate] = useState<ITask | null>(null);

  const deleteTask = (id: number) => {
    setTaskList(
      taskList.filter((task) => {
        return task.id !== id;
      })
    );
  };

  const editTask = (task: ITask) => {
    showModalOrHide(true);
    setTaskToUpdate(task);
  };

  const updateTask = (id: number, title: string, difficulty: number) => {
    const updatedTask: ITask = { id, title, difficulty };
    const updateItems: ITask[] = taskList.map((task) => {
      return task.id === updatedTask.id ? updatedTask : task;
    });
    setTaskList(updateItems);
    showModalOrHide(false);
  };

  const showModalOrHide = (display: boolean) => {
    const modal = document.querySelector("#modal");
    if (display) {
      modal?.classList.remove("hide");
    } else {
      modal?.classList.add("hide");
    }
  };

  return (
    <div className="main">
      <Modal
        children={
          <TaskForm
            btnText="Editar tarefa"
            taskList={taskList}
            setTaskList={setTaskList}
            taskUpdate={taskToUpdate}
            handleUpdate={updateTask}
          />
        }
      />
      <div>
        <h2>O que você vai fazer?</h2>
        <TaskForm btnText="Criar tarefa" taskList={taskList} setTaskList={setTaskList} />
      </div>
      <div>
        <h2>Suas tarefas:</h2>
        <TaskList taskList={taskList} deleteTask={deleteTask} editTask={editTask} />
      </div>
    </div>
  );
};

export default Main;
